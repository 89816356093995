import React, { Suspense, useEffect } from 'react';
import { Link, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@/css/slick_custom.css';
import {
    Block,
    StarsBl,
    ReviewDate,
    ItemReview,
    RateScore,
    PhotoItemBorder,
    PriceItemBorder,
    GridImg,
    PhotoGrid,
    PhotoGridCol2,
    PhotoItem,
    OpenHoursValue,
    Spacing20,
    LineGrayBox,
    ItemInfoCol2,
    InfoLabel,
    InfoValue,
    DetailContents,
    DesignerTabs,
    DetailDcBanner,
    DesignerProfile,
    Paragraph,
    LiStyled,
    CircleImg,
    Fz18Medium,
    Container,
    ItemDesigner,
    FlxbtFull,
    Mb5,
    Mb15,
    Mb20,
    DesignerInfo,
    RoundProfile,
    ListColumn,
    Fz15Bold,
    Fz16Medium,
    BtnFullLine,
    FullImg,
    Mb30,
    FlxMiddle,
    TabAccent2,
    Gap10,
    SectionDivider,
    ContainerPadding,
    DesignerPrices,
    TxRight,
    OrgPrice,
    ConsumerPrice,
    Flxbt,
    Fz20Bold,
    ColAccent,
    ColAccent2,
    FlxbtFullMb30,
    FlxbtFullMb20,
    StickyBtm,
    LinkFull,
    FlxMiddleMb15,
    FlxMiddleMb50,
    ColBlack,
    DesignerRates,
    Divider,
    ColBody,
    TabWrapper,
    ReviewThumb,
    ModalHeader,
    BtnCloseModal2,
    ModalPhoto,
    BtnModalPhotoLeft,
    BtnModalPhotoRight,
    FlxbtFullMb10,
    Pad20,
    BtnFullModal,
    LineCenter,
    Fz15,
    Gap30,
    Mb10,
    TxCent,
    PadVert20,
    Mb50,
    FlxGap10,
    BtnModalPhoto,
} from '@/css/style';
import useDesignerStore from '@/stores/designer/designer';
import {
    useDesignerDetailQuery,
    useDesignerMenuQuery,
    useDesignerReviewQuery,
} from '@/services/designerService';
import config from '@/config/index';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import { useBookMarkCreateMutation, useBookMarkDeleteMutation } from '@/services/userService';
import useUserStore from '@/stores/user/user';
import RatingStars from '@/components/RatingStars';
import altimg from '@/assets/img/altimg.png';
import arrowLeftWhite from '@/assets/img/arrow_left_wh.svg';
import arrowRightWhite from '@/assets/img/arrow_right_wh.svg';
import icCloseModal from '@/assets/img/ic_close_modal_gray.svg';
import icLikeOffFill from '@/assets/img/ic_like_off_fill.svg';
import icLikeOn from '@/assets/img/ic_like_on.svg';
import icReview from '@/assets/img/ic_review.svg';
import icShare from '@/assets/img/ic_share.svg';
import icFbCircle from '@/assets/img/sns_fb_circle.svg';
import icKakaoCircle from '@/assets/img/sns_kakao_circle.svg';
import icShareCircle from '@/assets/img/sns_link_circle.svg';
import icBlogCircle from '@/assets/img/sns_blog_circle.svg';
import icIgCircle from '@/assets/img/sns_ig_circle.svg';
import icCafeCircle from '@/assets/img/sns_cafe_circle.svg';
import icYtCircle from '@/assets/img/sns_yt_circle.svg';
import snsFb from '@/assets/img/sns_fb.svg';
import snsIg from '@/assets/img/sns_ig.svg';
import snsX from '@/assets/img/sns_x.svg';
import snsYt from '@/assets/img/sns_ytube.svg';
import { FaStar } from 'react-icons/fa';
import NewModal from '@/components/NewModal';
import NoContent from '@/components/NoContent';
import LocationMap from '@/components/LocationMap';
import { dayMappings, maskEmail } from '../../utils/helper';
import { toast } from 'react-toastify';
import { useIsMutating } from '@tanstack/react-query';

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <BtnModalPhoto className={className} style={{ ...style }} onClick={onClick}>
            <img src={arrowRightWhite} alt="" />
        </BtnModalPhoto>
    );
}
function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <BtnModalPhoto className={className} style={{ ...style }} onClick={onClick}>
            <img src={arrowLeftWhite} alt="" />
        </BtnModalPhoto>
    );
}
function DesignerDetail() {
    const ds = useDesignerStore();
    const us = useUserStore();
    const params = useParams();
    const location = useLocation();
    const isMutating = useIsMutating();
    const { navigate, navermaps } = useOutletContext();

    useEffect(() => {
        window.scrollTo(0, 0);

        return () => {
            ds.terminate();
        };
    }, []);

    const {
        data: designerData,
        refetch: designerDataRefetch,
        isLoading,
    } = useDesignerDetailQuery(params.id);
    const { data: menuData } = useDesignerMenuQuery(params.id);

    const { data: reviewData } = useDesignerReviewQuery(params.id);
    const BookMarkCreate = useBookMarkCreateMutation(() => designerDataRefetch());
    const BookMarkDelete = useBookMarkDeleteMutation(() => designerDataRefetch());

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [currentImageList, setCurrentImageList] = React.useState([]);
    const [currentImagePath, setCurrentImagePath] = React.useState(null);
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0); // 상태 추가

    function openModal(index, imgList, path) {
        setCurrentImageIndex(index);
        setCurrentImagePath(path);
        setCurrentImageList(imgList);
        setIsOpen(true); // 모달을 열어 해당 이미지를 보여줍니다.
    }

    function closeModal() {
        setIsOpen(false);
    }

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => {
            const newIndex = prevIndex + 1 >= currentImageList.length ? 0 : prevIndex + 1;
            return newIndex;
        });
    };
    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => {
            const newIndex = prevIndex - 1 < 0 ? currentImageList.length - 1 : prevIndex - 1;
            return newIndex;
        });
    };

    const kakaoShare = () => {
        window.Kakao.Link.sendDefault({
            objectType: 'feed',
            content: {
                title: designerData?.data.title,
                description: designerData?.data.introduce || '나만의 아름다움을 찾아드립니다.',
                imageUrl: `${config.DESIGNER_IMG_URL}${designerData?.data.profileImage?.filename}?size=150`,
                link: {
                    mobileWebUrl: config.CLIENT_HOST + location.pathname,
                    webUrl: config.CLIENT_HOST + location.pathname,
                },
            },
            buttons: [
                {
                    title: '자세히 보기',
                    link: {
                        mobileWebUrl: config.CLIENT_HOST + location.pathname,
                        webUrl: config.CLIENT_HOST + location.pathname,
                    },
                },
            ],
        });
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(config.CLIENT_HOST + location.pathname);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
        toast.success('복사되었습니다.', {
            position: 'top-right',
            autoClose: 500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });
    };

    if (!isLoading && !designerData) {
        return <NoContent message="해당 디자이너가 없습니다." />;
    }
    if (!isLoading && designerData) {
        return (
            <>
                <Container>
                    <DesignerProfile>
                        <ContainerPadding>
                            {/* 디자이너 프로필 영역 */}
                            <ItemDesigner>
                                <RoundProfile>
                                    <FullImg
                                        src={`${config.DESIGNER_IMG_URL}${designerData?.data.profileImage?.filename}?size=150`}
                                        onError={(e) => {
                                            e.currentTarget.src = altimg;
                                        }}
                                        alt="프로필 이미지"
                                    />
                                </RoundProfile>
                                <DesignerInfo>
                                    <FlxbtFullMb10>
                                        <ColAccent>{designerData?.data.shop.name}</ColAccent>
                                        <FlxMiddle>
                                            {designerData?.data.sns.map((items, index) => {
                                                const provider = snsOptions
                                                    .filter(
                                                        (option) =>
                                                            items.provider === option.provider
                                                    )
                                                    .map((option) => option.content);
                                                return (
                                                    <React.Fragment key={items.id}>
                                                        <a
                                                            href={items.link}
                                                            target="_blank"
                                                            rel="noreferrer noopener"
                                                        >
                                                            <img
                                                                src={provider}
                                                                alt={provider}
                                                                style={{
                                                                    width: '24px',
                                                                    height: '24px',
                                                                }}
                                                            />
                                                        </a>
                                                        {index <
                                                            designerData?.data.sns.length - 1 && (
                                                            <Gap10 />
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </FlxMiddle>
                                    </FlxbtFullMb10>
                                    <Mb15>
                                        <Fz20Bold>{designerData?.data.title}</Fz20Bold>
                                    </Mb15>
                                    <Flxbt as={Mb5}>
                                        <div>
                                            <img
                                                src={
                                                    require(`@/assets/img/ic_time.svg`).default
                                                }
                                                width={15}
                                                height={15}
                                                style={{ marginRight: '0.3rem' }}
                                                alt="시간"
                                            />
                                            {designerData?.data.avaliableTime.length
                                                ? `오늘 ${designerData?.data.avaliableTime[0]} 이후 예약 가능해요.`
                                                : designerData?.data.isDayoff
                                                ? '오늘은 휴무일이에요.'
                                                : '오늘 예약 마감되었어요.'}
                                        </div>
                                    </Flxbt>
                                    <FlxbtFullMb10>
                                        <DesignerRates>
                                            <img src={icReview} alt="후기" />{' '}
                                            <strong>{designerData?.data.ratingCount}</strong>
                                            <FaStar color="#FFBB00" />{' '}
                                            <strong>
                                                {designerData?.data.averageRating.toFixed(1)}
                                            </strong>
                                        </DesignerRates>
                                        <FlxMiddle>
                                            {us.user.user_id &&
                                                (designerData?.data.isBookmarked ? (
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            BookMarkDelete.mutate(params.id);
                                                        }}
                                                        disabled={!!isMutating}
                                                    >
                                                        <img src={icLikeOn} alt="좋아요" />
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            BookMarkCreate.mutate(params.id);
                                                        }}
                                                        disabled={!!isMutating}
                                                    >
                                                        <img src={icLikeOffFill} alt="좋아요" />
                                                    </button>
                                                ))}
                                            <Gap10 />
                                            {/* 메모: 이 버튼으로 공유하기 모달 열기 */}
                                            <button
                                                type="button"
                                                onClick={() => ds.toggleModal('share')}
                                            >
                                                <img
                                                    src={icShare}
                                                    style={{ width: '24px', height: '24px' }}
                                                />
                                            </button>
                                        </FlxMiddle>
                                    </FlxbtFullMb10>
                                </DesignerInfo>
                            </ItemDesigner>
                            {/* //디자이너 프로필 영역 */}
                        </ContainerPadding>
                        {(!!designerData?.data.career ||
                            !!designerData?.data.introduce ||
                            designerData?.data.images.length > 0) && <SectionDivider />}
                        <ContainerPadding>
                            {designerData?.data.career && (
                                <Block>
                                    <Fz18Medium $mb="1.538rem">디자이너 프로필</Fz18Medium>
                                    <ul>
                                        <LiStyled>{designerData?.data.career}</LiStyled>
                                    </ul>
                                </Block>
                            )}
                            {designerData?.data.introduce && (
                                <Block>
                                    <Fz18Medium $mb="1.538rem">디자이너 소개</Fz18Medium>
                                    <ul>
                                        <LiStyled>{designerData?.data.introduce}</LiStyled>
                                    </ul>
                                </Block>
                            )}
                            {designerData?.data.images.length > 0 && (
                                <Block>
                                    {/* 스타일 사진 */}
                                    <FlxbtFullMb20>
                                        <Fz18Medium>스타일 사진</Fz18Medium>
                                        <Fz15Bold>{designerData?.data.images.length}건</Fz15Bold>
                                    </FlxbtFullMb20>
                                    <Mb20>
                                        <PhotoGrid>
                                            {designerData?.data.images
                                                .slice(0, 9)
                                                .map((items, index) => {
                                                    return (
                                                        <PhotoItem
                                                            key={items.id}
                                                            onClick={() =>
                                                                openModal(
                                                                    index,
                                                                    designerData?.data.images.slice(
                                                                        0,
                                                                        9
                                                                    ),
                                                                    '/files/designer/'
                                                                )
                                                            }
                                                        >
                                                            <GridImg
                                                                src={`${config.DESIGNER_IMG_URL}${items.filename}?size=150`}
                                                                onError={(e) => {
                                                                    e.currentTarget.src = altimg;
                                                                }}
                                                            />
                                                        </PhotoItem>
                                                    );
                                                })}
                                        </PhotoGrid>
                                    </Mb20>
                                    {designerData?.data.images.length > 9 && (
                                        <BtnFullLine
                                            onClick={() => {
                                                navigate(`/styles/${params.id}`);
                                            }}
                                        >
                                            전체보기
                                        </BtnFullLine>
                                    )}
                                </Block>
                            )}

                            {/* //스타일 사진 */}
                            {/* 디자이너 할인 안내 */}
                            {/* 메모: 등록된 배너가 있을때만 노출되는 영역입니다 */}
                            {/* <Fz18Medium $mb="1.538rem">디자이너 할인 안내</Fz18Medium>
                        <DetailDcBanner>
                            <FullImg src="//shorturl.at/fjklN" alt="배너" />
                        </DetailDcBanner> */}
                            {/* //디자이너 할인 안내 */}
                        </ContainerPadding>
                    </DesignerProfile>
                    <FlxbtFull>
                        <DesignerTabs
                            $active={ds.category.price}
                            onClick={() => ds.toggleCategory('price')}
                        >
                            시술 메뉴
                        </DesignerTabs>
                        <DesignerTabs
                            $active={ds.category.shop}
                            onClick={() => ds.toggleCategory('shop')}
                        >
                            매장 정보
                        </DesignerTabs>
                        <DesignerTabs
                            $active={ds.category.review}
                            onClick={() => ds.toggleCategory('review')}
                        >
                            리뷰({reviewData?.data.docs.length || 0})
                        </DesignerTabs>
                    </FlxbtFull>
                    {/* 매장 정보 섹션 */}
                    <DetailContents $visible={ds.category.shop}>
                        {/* 매장 프로필 */}
                        <ContainerPadding>
                            <Fz20Bold $mb="1.538rem">{designerData?.data.shop.name}</Fz20Bold>
                            {designerData?.data.shop.tel ? (
                                <ItemInfoCol2 $mb="1.538rem">
                                    <InfoLabel>대표번호</InfoLabel>
                                    <InfoValue style={{ width: '76%' }}>
                                        <Flxbt>
                                            <span>{designerData?.data.shop.tel}</span>
                                            <LineGrayBox>
                                                <ColBody
                                                    href={`tel:${designerData?.data.shop.tel}`}
                                                    as="a"
                                                >
                                                    문의하기
                                                </ColBody>
                                            </LineGrayBox>
                                        </Flxbt>
                                    </InfoValue>
                                </ItemInfoCol2>
                            ) : null}
                            {designerData?.data.shop.address ? (
                                <ItemInfoCol2 $mb="1.538rem">
                                    <InfoLabel>매장위치</InfoLabel>
                                    <InfoValue>
                                        {designerData?.data.shop.address +
                                            ' ' +
                                            (designerData?.data.shop.address_detail || '')}
                                    </InfoValue>
                                </ItemInfoCol2>
                            ) : null}
                            {designerData?.data.shop.location && (
                                <Mb30>
                                    <Suspense fallback={null}>
                                        <LocationMap
                                            key={ds.category.shop ? 'shop-map' : 'hidden-map'}
                                            lat={designerData?.data.shop.location.coordinates[1]}
                                            lng={designerData?.data.shop.location.coordinates[0]}
                                            navermaps={navermaps}
                                        />
                                    </Suspense>
                                </Mb30>
                            )}
                            {designerData?.data.shop.homepage ? (
                                <ItemInfoCol2 $mb="2.3077rem">
                                    <InfoLabel>홈페이지</InfoLabel>
                                    <InfoValue>
                                        <a
                                            href={designerData?.data.shop.homepage}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            {designerData?.data.shop.homepage}
                                        </a>
                                    </InfoValue>
                                </ItemInfoCol2>
                            ) : null}

                            <Mb30>
                                <Paragraph $fz="1.154rem">
                                    {designerData?.data.shop.introduction}
                                </Paragraph>
                            </Mb30>
                            {designerData?.data.shop.main_image && (
                                <DetailDcBanner as={Mb30}>
                                    <FullImg
                                        src={`${config.SHOP_IMG_URL}${designerData?.data.shop.main_image.filename}?size=350`}
                                        onError={(e) => {
                                            e.currentTarget.src = altimg;
                                        }}
                                        alt="프로필 이미지"
                                        onClick={() =>
                                            openModal(
                                                0,
                                                [designerData?.data.shop.main_image],
                                                '/files/shop/'
                                            )
                                        }
                                    />
                                </DetailDcBanner>
                            )}

                            {/* 메모: 등록된 SNS가 있는 경우 */}
                            <FlxGap10 as={Mb50}>
                                {designerData?.data.shop.kakaochannel && (
                                    <Link to={designerData?.data.shop.kakaochannel} target="_blank">
                                        <img src={icKakaoCircle} alt="카카오채널 바로가기" />
                                    </Link>
                                )}
                                {designerData?.data.shop.youtube && (
                                    <Link to={designerData?.data.shop.youtube} target="_blank">
                                        <img src={icYtCircle} alt="유튜브채널 바로가기" />
                                    </Link>
                                )}
                                {designerData?.data.shop.instagram && (
                                    <Link to={designerData?.data.shop.instagram} target="_blank">
                                        <img src={icIgCircle} alt="인스타그램 바로가기" />
                                    </Link>
                                )}
                                {designerData?.data.shop.facebook && (
                                    <Link to={designerData?.data.shop.facebook} target="_blank">
                                        <img src={icFbCircle} alt="페이스북 바로가기" />
                                    </Link>
                                )}
                                {designerData?.data.shop.naverblog && (
                                    <Link to={designerData?.data.shop.naverblog} target="_blank">
                                        <img src={icBlogCircle} alt="네이버 블로그 바로가기" />
                                    </Link>
                                )}
                                {designerData?.data.shop.daumcafe && (
                                    <Link to={designerData?.data.shop.daumcafe} target="_blank">
                                        <img src={icCafeCircle} alt="다음 카페 바로가기" />
                                    </Link>
                                )}
                            </FlxGap10>
                            {/* //메모: 등록된 SNS가 있는 경우 */}
                            {/* //매장 프로필 */}
                            {/* 운영시간 안내 */}
                            <Fz18Medium $mb="1.538rem">운영시간 안내</Fz18Medium>
                            <ul>
                                {designerData?.data.shop.opening_hour.map((items, index) => {
                                    if (dayMappings[items.day]) {
                                        const isLastIteration =
                                            index ===
                                            designerData.data.shop.opening_hour.length - 1;
                                        return (
                                            <div key={index}>
                                                <FlxMiddleMb15 as="li">
                                                    <InfoLabel style={hourLabelStyle}>
                                                        {dayMappings[items.day].name}
                                                    </InfoLabel>
                                                    <OpenHoursValue>
                                                        {items.type === 'open' ? (
                                                            <>
                                                                <ColBlack>
                                                                    {`${items.from_time} ~ ${items.to_time}`}
                                                                </ColBlack>
                                                                <ColAccent>영업</ColAccent>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span>정기휴무</span>
                                                                <ColAccent2>휴무</ColAccent2>
                                                            </>
                                                        )}
                                                    </OpenHoursValue>
                                                </FlxMiddleMb15>
                                                {/* 마지막 반복인 경우 FlxMiddleMb50을 사용합니다. */}
                                                {isLastIteration && <FlxMiddleMb50 />}
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </ul>
                            {/* //운영시간 안내 */}
                            {/* 매장 편의서비스 안내 */}
                            {designerData?.data.shop.introduce ? (
                                <Mb50>
                                    <Fz18Medium $mb="1.538rem">매장 소개글</Fz18Medium>
                                    <Paragraph $fz="1.154rem" $col="#000">
                                        {designerData?.data.shop.introduce}
                                    </Paragraph>
                                </Mb50>
                            ) : null}
                            {/* 매장 편의서비스 안내 */}
                            {designerData?.data.shop.services ? (
                                <Mb50>
                                    <Fz18Medium $mb="1.538rem">매장 편의 서비스</Fz18Medium>
                                    <Paragraph $fz="1.154rem" $col="#000">
                                        {designerData?.data.shop.services}
                                    </Paragraph>
                                </Mb50>
                            ) : null}
                            {/* //매장 편의서비스 안내 */}
                            {/* 예약 주의사항 */}
                            {designerData?.data.shop.reservation_notice ? (
                                <Mb50>
                                    <Fz18Medium $mb="1.538rem">
                                        예약 시 주의 사항 공통 항목
                                    </Fz18Medium>
                                    <Paragraph $fz="1.154rem" $col="#000">
                                        {designerData?.data.shop.reservation_notice}
                                    </Paragraph>
                                </Mb50>
                            ) : null}
                            {/* //예약 주의사항 */}
                            {/* 추가 이미지 보기 */}
                            {/* 메모: 스타일 사진과 같은 형식으로 상세보기가 필요합니다 */}
                            {designerData?.data.shop.images.length > 0 && (
                                <PhotoGridCol2>
                                    {designerData?.data.shop.images.map((items, index) => (
                                        <PhotoItemBorder
                                            key={items.id}
                                            onClick={() =>
                                                openModal(
                                                    index,
                                                    designerData?.data.shop.images,
                                                    '/files/shop/'
                                                )
                                            }
                                        >
                                            <GridImg
                                                src={`${config.SHOP_IMG_URL}${items.filename}?size=168`}
                                                alt="이미지"
                                                onError={(e) => {
                                                    e.currentTarget.src = altimg;
                                                }}
                                            />
                                        </PhotoItemBorder>
                                    ))}
                                </PhotoGridCol2>
                            )}

                            {/* //추가 이미지 보기 */}
                        </ContainerPadding>
                    </DetailContents>
                    {/* //매장 정보 섹션 */}
                    {/* 가격 정보 섹션 */}
                    <DetailContents $visible={ds.category.price}>
                        {!menuData?.data.length ? (
                            <NoContent message="시술 메뉴가 없습니다." />
                        ) : (
                            <ContainerPadding>
                                <DesignerPrices>
                                    {menuData?.data.map((items) => (
                                        <PriceItemBorder key={items.id} $pd="1.154rem 0">
                                            <FlxbtFullMb10>
                                                <Fz16Medium>{items.name}</Fz16Medium>
                                                {items.normal_price === items.price ? (
                                                    <TxRight>
                                                        <ConsumerPrice>
                                                            {items.normal_price.toLocaleString()}원
                                                        </ConsumerPrice>
                                                    </TxRight>
                                                ) : (
                                                    <TxRight>
                                                        {items.price != 0 && (
                                                            <OrgPrice>
                                                                {items.normal_price.toLocaleString()}
                                                            </OrgPrice>
                                                        )}
                                                        <ConsumerPrice>
                                                            {items.price == 0
                                                                ? '무료'
                                                                : `${items.price.toLocaleString()}원`}
                                                        </ConsumerPrice>
                                                    </TxRight>
                                                )}
                                            </FlxbtFullMb10>
                                            {items.option ? <p>{items.option}</p> : null}
                                        </PriceItemBorder>
                                    ))}
                                </DesignerPrices>
                            </ContainerPadding>
                        )}
                        {params.id !== us.user.id && menuData?.data.length ? (
                            <StickyBtm
                                onClick={() => navigate(`/reservation/${params.id}`)}
                                $wd="100%"
                            >
                                <LinkFull $active>예약하기</LinkFull>
                            </StickyBtm>
                        ) : null}
                    </DetailContents>
                    {/* //가격 정보 섹션 */}
                    {/* 리뷰 섹션 */}
                    <DetailContents $visible={ds.category.review}>
                        <ContainerPadding>
                            {!reviewData?.data.docs.length && (
                                <NoContent message="작성된 리뷰가 없습니다." />
                            )}
                            {reviewData?.data.docs.length > 0 && (
                                <>
                                    <FlxbtFullMb30>
                                        <FlxMiddle>
                                            <RatingStars
                                                rating={reviewData?.data.docs[0].reservation.designer.averageRating.toFixed(
                                                    1
                                                )}
                                            />
                                            <Gap10 />
                                            <RateScore>
                                                <ColBlack>
                                                    {reviewData?.data.docs[0].reservation.designer.averageRating.toFixed(
                                                        1
                                                    )}
                                                </ColBlack>{' '}
                                                / 5.0
                                            </RateScore>
                                        </FlxMiddle>
                                        <FlxMiddle>
                                            <Fz18Medium>{reviewData?.data.totalDocs}</Fz18Medium>
                                            <Fz16Medium>건</Fz16Medium>
                                        </FlxMiddle>
                                    </FlxbtFullMb30>
                                    <TabWrapper>
                                        <TabAccent2
                                            $active={ds.order.distance}
                                            onClick={() => ds.toggleOrder('distance')}
                                        >
                                            최신순
                                        </TabAccent2>
                                        ・
                                        <TabAccent2
                                            $active={ds.order.rate}
                                            onClick={() => ds.toggleOrder('rate')}
                                        >
                                            인기순
                                        </TabAccent2>
                                    </TabWrapper>
                                    <Divider $mt="0.77rem" />
                                    <div>
                                        {reviewData?.data.docs.map((items) => {
                                            return (
                                                <ItemReview key={items.id}>
                                                    <FlxbtFull>
                                                        <Fz15Bold $col="#000">
                                                            {items.user.nickname
                                                                ? items.user.nickname
                                                                : maskEmail(items.user.user_id)}
                                                        </Fz15Bold>
                                                        <ReviewDate>
                                                            {' '}
                                                            {format(
                                                                new Date(items.created),
                                                                'yyyy. MM. dd',
                                                                {
                                                                    locale: ko,
                                                                }
                                                            )}{' '}
                                                        </ReviewDate>
                                                    </FlxbtFull>
                                                    <StarsBl>
                                                        <RatingStars rating={items.rating} />
                                                        &nbsp;&nbsp;{items.rating}
                                                    </StarsBl>
                                                    <Paragraph $fz="1.154rem">
                                                        {items.content}
                                                    </Paragraph>
                                                    <Spacing20 />
                                                    <Slider {...sliderStyle}>
                                                        {items.images.map((images, index) => (
                                                            <ReviewThumb
                                                                key={images.id}
                                                                onClick={() =>
                                                                    openModal(
                                                                        index,
                                                                        items.images,
                                                                        '/files/review/'
                                                                    )
                                                                }
                                                            >
                                                                <GridImg
                                                                    src={`${config.REVIEW_IMG_URL}${images.filename}?size=350`}
                                                                    alt="이미지"
                                                                    onError={(e) => {
                                                                        e.currentTarget.src =
                                                                            altimg;
                                                                    }}
                                                                />
                                                            </ReviewThumb>
                                                        ))}
                                                    </Slider>
                                                </ItemReview>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </ContainerPadding>
                    </DetailContents>
                    {/* //리뷰 섹션 */}
                </Container>
                <Modal ariaHideApp={false} style={slideShowStyles}>
                    <ModalHeader>
                        <BtnCloseModal2>
                            <img src={icCloseModal} alt="닫기" />
                        </BtnCloseModal2>
                        <Fz18Medium>상세보기(/)</Fz18Medium>
                    </ModalHeader>
                    <ModalPhoto>
                        <BtnModalPhotoLeft>
                            <img src={arrowLeftWhite} />
                        </BtnModalPhotoLeft>
                        <FullImg src="" />
                        <BtnModalPhotoRight>
                            <img src={arrowRightWhite} />
                        </BtnModalPhotoRight>
                    </ModalPhoto>
                </Modal>
                <Modal isOpen={modalIsOpen} ariaHideApp={false} style={slideShowStyles}>
                    <ModalHeader>
                        <BtnCloseModal2 onClick={closeModal}>
                            <img src={icCloseModal} alt="닫기" />
                        </BtnCloseModal2>
                        <Fz18Medium>
                            상세보기({currentImageIndex + 1}/{currentImageList.length})
                        </Fz18Medium>
                    </ModalHeader>
                    <ModalPhoto>
                        <BtnModalPhotoLeft onClick={() => prevImage()}>
                            <img src={arrowLeftWhite} />
                        </BtnModalPhotoLeft>
                        <FullImg
                            src={
                                config.API_SERVER_HOST +
                                currentImagePath +
                                currentImageList[currentImageIndex]?.filename +
                                '?size=390'
                            }
                            onError={(e) => {
                                e.currentTarget.src = altimg;
                            }}
                        />
                        <BtnModalPhotoRight onClick={() => nextImage()}>
                            <img src={arrowRightWhite} />
                        </BtnModalPhotoRight>
                    </ModalPhoto>
                </Modal>
                {/* 공유하기 모달 */}
                <NewModal isOpen={ds.modal.share}>
                    <Pad20>
                        <PadVert20 as={Mb20}>
                            <Fz15Bold $col="#000">공유하기</Fz15Bold>
                            <Divider $mt="1.538rem" $mb="1.538rem" />
                            <LineCenter as={Fz15}>
                                {/* 버튼?링크? 몰라서 일단 button으로 합니다*/}
                                <button type="button" onClick={kakaoShare}>
                                    <Mb10 as={TxCent}>
                                        <img src={icKakaoCircle} />
                                    </Mb10>
                                    카카오톡
                                </button>
                                <Gap30 />
                                <button
                                    type="button"
                                    onClick={() =>
                                        window.open(
                                            'http://www.facebook.com/sharer.php?u=' +
                                                encodeURIComponent(
                                                    config.CLIENT_HOST + location.pathname
                                                ),
                                            '_blank'
                                        )
                                    }
                                >
                                    <Mb10 as={TxCent}>
                                        <img src={icFbCircle} />
                                    </Mb10>
                                    페이스북
                                </button>
                                <Gap30 />
                                <button type="button" onClick={copyToClipboard}>
                                    <Mb10 as={TxCent}>
                                        <img src={icShareCircle} />
                                    </Mb10>
                                    링크복사
                                </button>
                            </LineCenter>
                        </PadVert20>
                        <BtnFullModal
                            style={{ backgroundColor: '#000', color: '#fff' }}
                            onClick={() => ds.toggleModal('share')}
                        >
                            닫기
                        </BtnFullModal>
                    </Pad20>
                </NewModal>
                {/* //공유하기 모달 */}
            </>
        );
    }
}
const sliderStyle = {
    autoplay: true,
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
};
const slideShowStyles = {
    overlay: {
        backgroundColor: 'unset',
        zIndex: 102,
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        overflowY: 'auto',
        top: 0,
        right: 'unset',
        bottom: 0,
        left: '50%',
        width: '100%',
        maxWidth: 440,
        padding: 0,
        transform: 'translateX(-50%)',
        border: 0,
        zIndex: 103,
        backgroundColor: 'rgba(0,0,0,.85)',
    },
};
const circleProfile = {
    border: '3px solid #00BF9D',
};
const hourLabelStyle = {
    width: '14%',
};
const snsOptions = [
    { id: 0, provider: 'Instagram', content: snsIg },
    { id: 1, provider: 'Twitter', content: snsX },
    { id: 2, provider: 'Facebook', content: snsFb },
    { id: 3, provider: 'Youtube', content: snsYt },
];

export default DesignerDetail;
